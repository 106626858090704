import service from '@/utils/request';

// @Tags RmPic
// @Summary 创建RmPic
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmPic true "创建RmPic"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /rmPic/createRmPic [post]
export var createRmPic = function createRmPic(data) {
  return service({
    url: "/rmPic/createRmPic",
    method: 'post',
    data: data
  });
};

// @Tags RmPic
// @Summary 删除RmPic
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmPic true "删除RmPic"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /rmPic/deleteRmPic [delete]
export var deleteRmPic = function deleteRmPic(data) {
  return service({
    url: "/rmPic/deleteRmPic",
    method: 'delete',
    data: data
  });
};

// @Tags RmPic
// @Summary 删除RmPic
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除RmPic"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /rmPic/deleteRmPic [delete]
export var deleteRmPicByIds = function deleteRmPicByIds(data) {
  return service({
    url: "/rmPic/deleteRmPicByIds",
    method: 'delete',
    data: data
  });
};

// @Tags RmPic
// @Summary 更新RmPic
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmPic true "更新RmPic"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /rmPic/updateRmPic [put]
export var updateRmPic = function updateRmPic(data) {
  return service({
    url: "/rmPic/updateRmPic",
    method: 'put',
    data: data
  });
};

// @Tags RmPic
// @Summary 用id查询RmPic
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmPic true "用id查询RmPic"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /rmPic/findRmPic [get]
export var findRmPic = function findRmPic(params) {
  return service({
    url: "/rmPic/findRmPic",
    method: 'get',
    params: params
  });
};

// @Tags RmPic
// @Summary 分页获取RmPic列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取RmPic列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /rmPic/getRmPicList [get]
export var getRmPicList = function getRmPicList(params) {
  return service({
    url: "/rmPic/getRmPicList",
    method: 'get',
    params: params
  });
};